.App {
  /* text-align: center; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


